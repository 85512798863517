require('./bootstrap');
require('./glider')

// require('sweetalert2');

import $ from 'jquery';
window.$ = window.jQuery = $;

// import Swal from 'sweetalert2';
// const Swal = window.swal = require('sweetalert2');
// var Swal = require('sweetalert2');


import Swal from 'sweetalert2';
window.Swal = Swal;

// const toast = Swal.mixin({
//     toast: true,
//     position: 'top-end',
//     showConfirmButton: false,
//     timer: 3000
// });
// window.toast = toast;